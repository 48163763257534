<template>
    <div>
        <p v-if="error">Error: {{ error }}</p>
        <p v-else>Google Loading...</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: null,
            error: null,
        };
    },
    methods: {
        async exchangeCodeForTokensAndSave(code) {
            const domain = 'https://admin-panel.' + this.getDomain(window.location.hostname) + ':8081/oauth2callback';

            try {
                // Authorization Code ile Token Al
                const response = await fetch('https://oauth2.googleapis.com/token', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams({
                        client_id: process.env.VUE_APP_CLIENT_ID,
                        client_secret: process.env.VUE_APP_CLIENT_SECRET,
                        code: code,
                        grant_type: 'authorization_code',
                        redirect_uri: domain,
                    }).toString(),
                });

                const data = await response.json();

                if (data.refresh_token) {
                    console.log('Refresh Token:', data.refresh_token);

                    // Refresh Token'ı Veritabanına Kaydetmek İçin Sunucu API'sine Gönder
                    //await this.saveRefreshToken(data.refresh_token);
                } else {
                    console.error('No refresh token received');
                }
            } catch (error) {
                console.error('Error exchanging code for tokens:', error);
            }
        },
        getDomain(hostname) {
            const regex = /(?:[\w-]+\.)?([\w-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)(?::\d+)?$/;
            const match = hostname.match(regex);
            return match ? match[1] : null;
        },
        async login() {
            try {
                const response = await this.$http.post('/auth/login', { token: this.token });
                this.$store.commit('setUser', response.data.user);
                this.$router.push('/');
            } catch (error) {
                console.error(error);
            }
        },
    },
    async created() {
        const params = new URLSearchParams(window.location.search);

        if (params.has('error')) {
            this.error = params.get('error');
        } else {
            this.token = params.get('code');
            if (this.token) {
                const status = await this.$store.dispatch('CALENDAR_ADD', {type:'token', token: this.token});
                if(status || !status){
                    this.$router.push({path : '/calendar'});
                }
                //this.exchangeCodeForTokensAndSave(this.token);
            }
        }
    },
};
</script>